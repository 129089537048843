const pickUpItemsFields = {
    "name": "pickUpItemsInfo",
    "exportOption": "monthly",
    "mainCollection": "pickUpItems",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": "id",
            "exportArray": null
        },{
            "fieldName": "imei",
            "displayName": "IMEI",
            "exportArray": null
        }, {
            "fieldName": "isBooked",
            "displayName": "Device is booked",
            "exportArray": null
        }, {
            "fieldName": "type",
            "displayName": "Type",
            "exportArray": null
        }]
    }, {
        "collection": "articles",
        "primaryKey": "articleId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "description",
            "displayName": "Description",
            "exportArray": null
        }]
    }, {
        "collection": "pickUps",
        "primaryKey": "pickUpId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "number",
            "displayName": "Order Number",
            "exportArray": null
        }, {
            "fieldName": "status",
            "displayName": "Status",
            "exportArray": null
        }, {
            "fieldName": "pickUpDate",
            "displayName": "Pick-Up Date",
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": "Created At of pick up number",
            "exportArray": null
        }]
    }, {
        "collection": "businessentities",
        "primaryKey": "businessEntityCode",
        "foreignKey": "code",
        "exportFields": [{
            "fieldName": "code",
            "displayName": "Business Entity Code",
            "exportArray": null
        }, {
            "fieldName": "name",
            "displayName": "Business Entity Name",
            "exportArray": null
        }, {
            "fieldName": "dealerCode",
            "displayName": "Dealer Code",
            "exportArray": null
        }, {
            "fieldName": "aggregatedPOSCode",
            "displayName": "Aggregated POS Code",
            "exportArray": null
        },{
            "fieldName": "email",
            "displayName": "Email",
            "exportArray": null
        }, {
            "fieldName": "address.street",
            "displayName": "Street",
            "exportArray": null
        }, {
            "fieldName": "address.number",
            "displayName": "Number",
            "exportArray": null
        }, {
            "fieldName": "address.zipCode",
            "displayName": "ZIP Code",
            "exportArray": null
        }, {
            "fieldName": "address.city",
            "displayName": "City",
            "exportArray": null
        }, {
            "fieldName": "address.provinceCode",
            "displayName": "Province Code",
            "exportArray": null
        }]
    }]
}

const pickUpItemsAdditionalFields = {
    "name": "pickUpItemsInfo",
    "exportOption": "monthly",
    "mainCollection": "pickUpItems",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": "id",
            "exportArray": null
        },{
            "fieldName": "imei",
            "displayName": "IMEI",
            "exportArray": null
        }, {
            "fieldName": "isBooked",
            "displayName": "Device is booked",
            "exportArray": null
        }, {
            "fieldName": "deviceCondition",
            "displayName": "Device Condition",
            "exportArray": null
        }, {
            "fieldName": "type",
            "displayName": "Type",
            "exportArray": null
        }]
    }, {
        "collection": "articles",
        "primaryKey": "articleId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "description",
            "displayName": "Description",
            "exportArray": null
        }]
    }, {
        "collection": "pickUps",
        "primaryKey": "pickUpId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "number",
            "displayName": "Order Number",
            "exportArray": null
        }, {
            "fieldName": "status",
            "displayName": "Status",
            "exportArray": null
        }, {
            "fieldName": "pickUpDate",
            "displayName": "Pick-Up Date",
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": "Created At of pick up number",
            "exportArray": null
        }]
    }, {
        "collection": "businessentities",
        "primaryKey": "businessEntityCode",
        "foreignKey": "code",
        "exportFields": [{
            "fieldName": "code",
            "displayName": "Business Entity Code",
            "exportArray": null
        }, {
            "fieldName": "name",
            "displayName": "Business Entity Name",
            "exportArray": null
        }, {
            "fieldName": "dealerCode",
            "displayName": "Dealer Code",
            "exportArray": null
        }, {
            "fieldName": "aggregatedPOSCode",
            "displayName": "Aggregated POS Code",
            "exportArray": null
        },{
            "fieldName": "email",
            "displayName": "Email",
            "exportArray": null
        }, {
            "fieldName": "address.street",
            "displayName": "Street",
            "exportArray": null
        }, {
            "fieldName": "address.number",
            "displayName": "Number",
            "exportArray": null
        }, {
            "fieldName": "address.zipCode",
            "displayName": "ZIP Code",
            "exportArray": null
        }, {
            "fieldName": "address.city",
            "displayName": "City",
            "exportArray": null
        }, {
            "fieldName": "address.provinceCode",
            "displayName": "Province Code",
            "exportArray": null
        }]
    }]
}

const pickUpExportFields = {
    "name": "pickUpOrderInfo",
    "exportOption": "monthly",
    "mainCollection": "pickUps",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": "id",
            "exportArray": null
        },{
            "fieldName": "number",
            "displayName": "Pickup Order Code",
            "exportArray": null
        }, {
            "fieldName": "status",
            "displayName": "Pickup Order Status",
            "exportArray": null
        }, {
            "fieldName": "pickUpDate",
            "displayName": "Pickup Date",
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": "Created At of pick up number",
            "exportArray": null
        }]
    }, {
        "collection": "logistics_deliveries",
        "primaryKey": "deliveryId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "courierAdapterCode",
            "displayName": "Courier",
            "exportArray": null
        }, {
            "fieldName": "tracking.trackingNumber",
            "displayName": "AWB Courier Number",
            "exportArray": null
        }]
    }, {
        "collection": "businessentities",
        "primaryKey": "businessEntityCode",
        "foreignKey": "code",
        "exportFields": [{
            "fieldName": "code",
            "displayName": "Business Entity Code",
            "exportArray": null
        }, {
            "fieldName": "name",
            "displayName": "Business Entity Name",
            "exportArray": null
        }, {
            "fieldName": "dealerCode",
            "displayName": "Dealer Code",
            "exportArray": null
        }, {
            "fieldName": "aggregatedPOSCode",
            "displayName": "Aggregated POS Code",
            "exportArray": null
        },{
            "fieldName": "email",
            "displayName": "Email",
            "exportArray": null
        }, {
            "fieldName": "address.street",
            "displayName": "Street",
            "exportArray": null
        }, {
            "fieldName": "address.number",
            "displayName": "Number",
            "exportArray": null
        }, {
            "fieldName": "address.zipCode",
            "displayName": "ZIP Code",
            "exportArray": null
        }, {
            "fieldName": "address.city",
            "displayName": "City",
            "exportArray": null
        }, {
            "fieldName": "address.provinceCode",
            "displayName": "Province Code",
            "exportArray": null
        }]
    }]
}

const pickUpExportAdditionalFields = {
    "name": "pickUpOrderInfo",
    "exportOption": "monthly",
    "mainCollection": "pickUps",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": "id",
            "exportArray": null
        },{
            "fieldName": "number",
            "displayName": "Pickup Order Code",
            "exportArray": null
        }, {
            "fieldName": "status",
            "displayName": "Pickup Order Status",
            "exportArray": null
        }, {
            "fieldName": "pickUpDate",
            "displayName": "Pickup Date",
            "exportArray": null
        }, {
            "fieldName": "parcelCondition",
            "displayName": "Parcel Condition",
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": "Created At of pick up number",
            "exportArray": null
        }]
    }, {
        "collection": "logistics_deliveries",
        "primaryKey": "deliveryId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "courierAdapterCode",
            "displayName": "Courier",
            "exportArray": null
        }, {
            "fieldName": "tracking.trackingNumber",
            "displayName": "AWB Courier Number",
            "exportArray": null
        }]
    }, {
        "collection": "businessentities",
        "primaryKey": "businessEntityCode",
        "foreignKey": "code",
        "exportFields": [{
            "fieldName": "code",
            "displayName": "Business Entity Code",
            "exportArray": null
        }, {
            "fieldName": "name",
            "displayName": "Business Entity Name",
            "exportArray": null
        }, {
            "fieldName": "dealerCode",
            "displayName": "Dealer Code",
            "exportArray": null
        }, {
            "fieldName": "aggregatedPOSCode",
            "displayName": "Aggregated POS Code",
            "exportArray": null
        },{
            "fieldName": "email",
            "displayName": "Email",
            "exportArray": null
        }, {
            "fieldName": "address.street",
            "displayName": "Street",
            "exportArray": null
        }, {
            "fieldName": "address.number",
            "displayName": "Number",
            "exportArray": null
        }, {
            "fieldName": "address.zipCode",
            "displayName": "ZIP Code",
            "exportArray": null
        }, {
            "fieldName": "address.city",
            "displayName": "City",
            "exportArray": null
        }, {
            "fieldName": "address.provinceCode",
            "displayName": "Province Code",
            "exportArray": null
        }]
    }]
}

export const remarksExport = {
    "name": "remarks",
    "exportOption": "monthly",
    "mainCollection": "remarks",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "countryCode",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "creatorId",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "creatorDisplayName",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "content",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "entityName",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "entityId",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "updatedAt",
            "displayName": null,
            "exportArray": null
        }]
    }]   
};

export const boxDeliveriesExport = () => {
    return (
        {
            "name": "logistics_deliveries",
            "exportOption": "monthly",
            "mainCollection": "logistics_deliveries",
            "exportItems": [
                {
                    "collection": null,
                    "primaryKey": null,
                    "foreignKey": null,
                    "filterField": [
                        "usageType"
                    ],
                    "filterValue": [
                        "boxOrder"
                    ],
                    "exportFields": [
                        {
                            "fieldName": "salesOrderNumber",
                            "displayName": "Sales Order Number",
                            "exportArray": null
                        },
                        {
                            "fieldName": "businessEntity",
                            "displayName": "Business Entity Code",
                            "exportArray": null
                        },
                        {
                            "fieldName": "deliveryItems",
                            "displayName": "Quantity",
                            "exportArray": {
                                "arrayFields": [
                                    "quantity"
                                ],
                                "arrayIndex": "0"
                            }
                        },
                        {
                            "fieldName": "deliveryItems",
                            "displayName": "Status",
                            "exportArray": {
                                "arrayFields": [
                                    "status"
                                ],
                                "arrayIndex": "0"
                            }
                        },
                        {
                            "fieldName": "createdAt",
                            "displayName": "Created At",
                            "exportArray": null
                        }
                    ]
                }
            ]
        })
};

export const pickUpsExportParams = (authorities) => {
    if (authorities.includes('PICK_UP_OFFICER')) {
        return pickUpExportFields;
    } else if (authorities.includes('PICK_UP_MANAGER')) {
        return pickUpExportAdditionalFields;
    } else if (authorities.includes('VIDEO_CONTROLLER')) {
        return pickUpExportAdditionalFields;
    }
}

export const pickUpItemsExportParams = (authorities) => {
    if (authorities.includes('PICK_UP_OFFICER')) {
        return pickUpItemsFields;
    } else if (authorities.includes('PICK_UP_MANAGER')) {
        return pickUpItemsAdditionalFields;
    } else if (authorities.includes('VIDEO_CONTROLLER')) {
        return pickUpItemsAdditionalFields;
    }
}

export const tradeInDeatilListExport = (createdAtFrom, createdAtTo) => {
    let filterField = [];
    let filterValue = [];

    if (createdAtFrom) {
        filterField.push("createdAt.from");
        filterValue.push(createdAtFrom);
    }

    if (createdAtTo) {
        filterField.push("createdAt.to");
        filterValue.push(createdAtTo);
    }
    return (
        {
            "name": "tradeInsInfo",
            "exportOption": "allTime",
            "mainCollection": "tradeIns",
            "skipFilterValueCheck": true,
            "exportItems": [{
                "collection": null,
                "primaryKey": null,
                "foreignKey": null,
                "filterField": filterField,
                "filterValue": filterValue,
                "exportFields": [{
                    "fieldName": "_id",
                    "displayName": "Trade In ID",
                    "exportArray": null
                }, {
                    "fieldName": "countryCode",
                    "displayName": "Country",
                    "exportArray": null
                }, {
                    "fieldName": "carrierCode",
                    "displayName": "Carrier Code",
                    "exportArray": null
                }, {
                    "fieldName": "imei",
                    "displayName": "IMEI",
                    "exportArray": null
                }, {
                    "fieldName": "tradeInDevice.make",
                    "displayName": "Make",
                    "exportArray": null
                }, {
                    "fieldName": "tradeInDevice.nickname",
                    "displayName": "Model",
                    "exportArray": null
                }, {
                    "fieldName": "tradeInDevice.capacityDescription",
                    "displayName": "Capacity",
                    "exportArray": null
                }, {
                    "fieldName": "grade",
                    "displayName": "Grade",
                    "exportArray": null
                }, {
                    "fieldName": "values",
                    "displayName": "Trade In Value",
                    "exportArray": {
                        "arrayFields": ["value.currencyCode", "value.amount"]
                    }
                }, {
                    "fieldName": "bonus.currencyCode",
                    "displayName": "Bonus Value",
                    "exportArray": null
                }, {
                    "fieldName": "bonus.amount",
                    "displayName": "Bonus Value",
                    "exportArray": null
                }, {
                    "fieldName": "tradeInPlusBonus.currencyCode",
                    "displayName": "Trade In + Bonus Value",
                    "exportArray": null
                }, {
                    "fieldName": "tradeInPlusBonus.amount",
                    "displayName": "Trade In + Bonus Value",
                    "exportArray": null
                }, {
                    "fieldName": "testResults",
                    "displayName": "Diagnostic Result",
                    "exportArray": null
                }, {
                    "fieldName": "status",
                    "displayName": "Status",
                    "exportArray": null
                }, {
                    "fieldName": "createdAt",
                    "displayName": "Created Date",
                    "exportArray": null
                }, {
                    "fieldName": "updatedAt",
                    "displayName": "Updated Date",
                    "exportArray": null
                }]
            }, {
                "collection": "tradeIns_diagnostic",
                "primaryKey": "_id",
                "foreignKey": "tradeInId",
                "exportFields": [{
                    "fieldName": "businessEntity.name",
                    "displayName": "Business Entity Name",
                    "exportArray": null
                }, {
                    "fieldName": "staffInfo.firstName",
                    "displayName": "Staff FN",
                    "exportArray": null
                }, {
                    "fieldName": "staffInfo.lastName",
                    "displayName": "Staff LN",
                    "exportArray": null
                }, {
                    "fieldName": "businessEntity.code",
                    "displayName": "Code",
                    "exportArray": null
                }, {
                    "fieldName": "businessEntity.address.addressLine1",
                    "displayName": "Line 1",
                    "exportArray": null
                }, {
                    "fieldName": "businessEntity.address.addressLine2",
                    "displayName": "Line 2",
                    "exportArray": null
                }, {
                    "fieldName": "businessEntity.address.addressLine3",
                    "displayName": "Line 3",
                    "exportArray": null
                }, {
                    "fieldName": "businessEntity.address.city",
                    "displayName": "City",
                    "exportArray": null
                }, {
                    "fieldName": "businessEntity.address.state",
                    "displayName": "State",
                    "exportArray": null
                }, {
                    "fieldName": "businessEntity.address.zipCode",
                    "displayName": "zipCode",
                    "exportArray": null
                }]
            }, {
                "collection": "tradeUps",
                "primaryKey": "_id",
                "foreignKey": "tradeInId",
                "exportFields": [{
                    "fieldName": "imei",
                    "displayName": "Trade Up IMEI",
                    "exportArray": null
                }, {
                    "fieldName": "tradeUpDevice.make",
                    "displayName": "Trade Up Make",
                    "exportArray": null
                }, {
                    "fieldName": "tradeUpDevice.nickname",
                    "displayName": "Trade Up Model",
                    "exportArray": null
                }, {
                    "fieldName": "invoiceNumber",
                    "displayName": "Sales Invoice No.",
                    "exportArray": null
                }, {
                    "fieldName": "tradeUpValue.currencyCode",
                    "displayName": "Trade Up SRP",
                    "exportArray": null
                }, {
                    "fieldName": "tradeUpValue.amount",
                    "displayName": "Trade Up SRP",
                    "exportArray": null
                }, {
                    "fieldName": "eligibleTopUpValue.currencyCode",
                    "displayName": "Trade Up Token",
                    "exportArray": null
                }, {
                    "fieldName": "eligibleTopUpValue.amount",
                    "displayName": "Trade Up Token",
                    "exportArray": null
                }]
            }]
        })
};