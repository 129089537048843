import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    MERCHANT_PRODUCT_GET_CARRIER_CODES,
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getCarrierCodes = () => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/merchantProducts/getCarrierCodes`)
        .then(response => {
            dispatch({
                type: MERCHANT_PRODUCT_GET_CARRIER_CODES,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}
