export const BUSINESS_ENTITY_CHECK_EXISTENCE = 'businessEntityCheckExistence';
export const BUSINESS_ENTITY_CHECK_EXISTENCE_RESULT = 'businessEntityCheckExistenceResult';
export const BUSINESS_ENTITY_RESET_EXISTENCE = 'businessEntityResetExistence';
export const BUSINESS_ENTITY_CLEAR_FILTER = 'businessEntityClearFilter';
export const BUSINESS_ENTITY_FILTER = 'businessEntityFilter';
export const BUSINESS_ENTITY_GET_DETAILS = 'businessEntityGetDetails';
export const BUSINESS_ENTITY_GET_LIST = 'businessEntityGetList';
export const BUSINESS_ENTITY_SORT = 'businessEntitySort';
export const DELIVERY_GET = 'deliveryGet';
export const DELIVERY_GET_BOX_ORDER = 'deliveryGetBoxOrder';
export const DELIVERY_GET_BOX_ORDERS = 'deliveryGetBoxOrders';
export const DELIVERY_BOX_CLEAR_FILTER = 'deliveryBoxClearFilter';
export const DELIVERY_BOX_FILTER = 'deliveryBoxFilter';
export const DELIVERY_BOX_SORT = 'deliveryBoxSort';
export const DELIVERY_CREATED_BOX_ORDER = 'deliveryCreatedBoxOrder';
export const DELIVERY_UNSET = 'deliveryUnset';
export const ERROR = 'error';
export const EXPORT_FILE_CLEAR = 'exportFileClear';
export const EXPORT_FILE_GET = 'exportFileGet';
export const EXTENDED_WARRANTY_CLAIM_GET_LIST = 'extendedWarrantyClaimGetList';
export const EXTENDED_WARRANTY_CLAIM_GET_DETAILS = 'extendedWarrantyClaimGetDetails';
export const EXTENDED_WARRANTY_CLAIM_GET_SERVICE_LIMIT = 'extendedWarrantyClaimGetServiceLimit';
export const EXTENDED_WARRANTY_CLAIM_FILTER = 'extendedWarrantyClaimFilter';
export const EXTENDED_WARRANTY_CLAIM_CLEAR_FILTER = 'extendedWarrantyClaimClearFilter';
export const EXTENDED_WARRANTY_CLAIM_SORT = 'extendedWarrantyClaimSort';
export const HIDE_CONFIRMATION_DIALOG = 'hideConfirmationDialog';
export const LOG_IN = 'logIn';
export const LOG_OUT = 'logOut';
export const MERCHANT_USER_CLEAR_FILTER = 'merchantUserClearFilter';
export const MERCHANT_USER_CREATE = 'merchantUserCreate';
export const MERCHANT_USER_FILTER = 'merchantUserFilter';
export const MERCHANT_USER_GET_DETAILS = 'merchantUserGetDetails';
export const MERCHANT_USER_GET_LIST = 'merchantUserGetList';
export const MERCHANT_USER_SORT = 'merchantUserSort';
export const MERCHANT_USER_UPDATE = 'merchantUserUpdate';
export const CUSTOMER_CLEAR_FILTER = 'customerClearFilter';
export const CUSTOMER_FILTER = 'customerFilter';
export const CUSTOMER_GET_LIST = 'customerGetList';
export const CUSTOMER_GET_DETAILS = 'customerGetDetails';
export const CUSTOMER_SORT = 'customerSort';
export const OTP_GET = 'otpGet';
export const PASSWORD_EXPIRED = 'passwordExpired';
export const PICK_UP_CLEAR_DOCUMENTS = 'clearDocuments';
export const PICK_UP_CLEAR_FILTER = 'pickUpClearFilter';
export const PICK_UP_FILTER = 'pickUpFilter';
export const PICK_UP_GET_DETAILS = 'pickUpGetDetails';
export const PICK_UP_GET_DOCUMENTS = 'pickUpGetDocuments';
export const PICK_UP_GET_DOCUMENTS_IN_PROGRESS = 'pickUpGetDocumentsInProgress';
export const PICK_UP_GET_LIST = 'pickUpGetList';
export const PICK_UP_ITEM_CLEAR_FILTER = 'pickUItemClearFilter';
export const PICK_UP_ITEM_FILTER = 'pickUpItemFilter';
export const PICK_UP_ITEM_GET_LIST = 'pickUpItemGetList';
export const PICK_UP_ITEM_SORT = 'pickUpItemSort';
export const PICK_UP_ITEM_UPDATE = 'pickUpItemUpdate';
export const PICK_UP_SORT = 'pickUpSort';
export const PICK_UP_UPDATE_COMPLIANCE_BREACHED = 'pickUpUpdateComplianceBreached';
export const PICK_UP_UPDATE_PARCEL_CONDITION = 'pickUpUpdateParcelCondition';
export const PICK_UP_UPDATE_PICK_UP_DATE = 'pickUpUpdatePickUpDate';
export const PICK_UP_UPDATED_DETAILS = 'pickUpUpdatedDetails';
export const PLAN_GET_LIST = 'planGetList';
export const DOWNLOAD_REQUEST_GET_LIST = 'downloadRequestGetList';
export const DOWNLOAD_REQUEST_FILTER = 'downloadRequestFilter';
export const DOWNLOAD_REQUEST_CLEAR_FILTER = 'downloadRequestClearFilter';
export const DOWNLOAD_REQUEST_SORT = 'downloadRequestSort';
export const SUBSCRIPTION_GET_LIST = 'subscriptionGetList';
export const SUBSCRIPTION_GET_DETAILS = 'subscriptionGetDetails';
export const SUBSCRIPTION_GET_PARTNER_ADDITIONAL_DETAILS = 'subscriptionGetPartnerAddtionalDetails'
export const SUBSCRIPTION_FILTER = 'subscriptionFilter';
export const SUBSCRIPTION_CLEAR_FILTER = 'subscriptionClearFilter';
export const SUBSCRIPTION_SORT = 'subscriptionSort';
export const REMARK_CREATE = 'remarkCreate';
export const REMARK_GET_LIST = 'getRemarkList';
export const REQUEST_FAILURE = 'requestFailure';
export const REQUEST_START = 'requestStart';
export const REQUEST_SUCCESS = 'requestSuccess';
export const SEARCH_GET_RESULT = 'searchGetResult';
export const SEARCH_SET_FACET_PARAM_VALUE = 'searchSetFacetParamValue';
export const SEARCH_UNSET_STATE = 'searchUnsetState';
export const SET_RESET_PASSWORD_TOKEN = 'setResetPasswordToken';
export const SHOW_CONFIRMATION_DIALOG = 'showConfirmationDialog';
export const SHOW_CONTENT_LOADER = 'showContentLoader';
export const SHOW_ERROR = 'showError';
export const SHOW_SUCCESS = 'showSuccess'
export const SIMPLE_DIALOG_CLOSE = 'simpleDialogClose';
export const SWAP_GET_LIST = 'swapGetList';
export const SWAP_GET_DETAILS = 'swapGetDetails';
export const SWAP_UPDATE = 'swapUpdate';
export const SWAP_FILTER = 'swapFilter';
export const SWAP_CLEAR_FILTER = 'swapClearFilter';
export const SWAP_SORT = 'swapSort';
export const TRADE_IN_TRANSACTION_GET_LIST = 'tradeInTransactionGetList';
export const TRADE_IN_TRANSACTION_GET_DETAILS = 'tradeInTransactionGetDetails'
export const TRADE_IN_TRANSACTION_GET_QUESTIONNAIRE = 'tradeInTransactionGetQuestionnaire'
export const TRADE_IN_TRANSACTION_GET_ELIGIBLE_ARTICLES = 'tradeInTransactionGetEligibleArticles'
export const TRADE_IN_TRANSACTION_FILTER = 'tradeInTransactionFilter';
export const TRADE_IN_TRANSACTION_CLEAR_FILTER = 'tradeInTransactionClearFilter';
export const TRADE_IN_TRANSACTION_SORT = 'tradeInTransactionSort';
export const TRADE_IN_DETAIL_GET_LIST = 'tradeInDetailGetList';
export const TRADE_IN_DETAIL_LIST_FILTER = 'tradeInDetailListFilter'
export const TRADE_IN_DETAIL_LIST_CLEAR_FILTER = 'tradeInDetailListClearFilter'
export const TRADE_IN_DETAIL_LIST_SORT = 'tradeInDetailListSort'
export const VOUCHER_CODE_GET_DETAILS = 'voucherCodeGetDetails';
export const VOUCHER_CODE_CLEAR_FILTER = 'voucherCodeClearFilter';
export const VOUCHER_CODE_FILTER = 'voucherCodeFilter';
export const VOUCHER_CODE_GET_LIST = 'voucherCodeGetList';
export const VOUCHER_CODE_SORT = 'voucherCodeSort';
export const VOUCHER_CODE_UPDATE = 'voucherCodeUpdate';
export const MERCHANT_PRODUCT_GET_CARRIER_CODES = 'merchantProductGetCarrierCodes';