import {
    MERCHANT_PRODUCT_GET_CARRIER_CODES
} from "../actions/types";

const INITIAL_STATE = {
    carrierCodes: []
}

const merchantProductReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MERCHANT_PRODUCT_GET_CARRIER_CODES:
            console.log("RES", action.payload.data)
            return {
                ...state,
                carrierCodes: action.payload.data
            }
        default:
            return state;
    }
}

export default merchantProductReducer;